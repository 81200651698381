/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import routes from '../router/routes'
import { IRoutes } from '../interfaces/IRoutes'
import Profile from '../components/Profile'
import MobileMenu from '../components/MobileMenu'

const TemplateDefault = (props: any) => {
    const { children } = props

    const { session } = useSelector((state: any) => state)

    const [menu, setMenu] = useState<IRoutes[]>([])
    const navigate = useNavigate()

    useEffect(() => {
        if (!session.isLogged) {
            navigate('/signin')
            return
        }

        const menu = routes?.filter(
            (route: any) =>
                route.menu &&
                (route.permissions.includes(session?.profile?.roles[0]?.value) || route.permissions.includes('all'))
        )

        menu.map((item: any) => {
            if (item.path === window.location.pathname) {
                item.selected = true
                return item
            } else {
                item.selected = false
            }
            
            if (item.regularExpression) {
                if (item.regularExpression.test(window.location.pathname)) {
                    item.selected = true
                } else {
                    item.selected = false
                }
            }

            return item
        })
        setMenu(menu)
    }, [session])

    const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { ...props })
        }
        return child
    })

    return (
        <div className="w-full flex h-full overflow-hidden">
            {/* Menu */}
            <div className="hidden sm:block w-[300px] h-full px-5 text-gray-700 border-r">
                <div className="text-center w-full text-[24px] mt-10">Lectogram</div>

                <ul className="mt-10">
                    {menu.map((item: any, index: number) => (
                        <Link to={item.menu?.url} key={index} className="text-gray-600 hover:text-gray-400">
                            <li
                                className={`py-3 px-2 ${item.selected ? 'bg-gray-100 rounded shadow' : ''}`}
                                key={index}
                            >
                                <i className={item?.menu?.icon} />
                                {item?.menu?.name}
                            </li>
                        </Link>
                    ))}
                    <Link to="/signout" className="text-gray-600 hover:text-gray-400">
                        <li className={`py-3 px-2`}>
                            <i className="zmdi zmdi-sign-in me-2 rotate-180" />
                            Cerrar Sesion
                        </li>
                    </Link>
                </ul>
            </div>

            {/* Container */}
            <div className="w-full">
                <header className="h-[6vh] shadow-sm border-b flex items-center px-5 py-8 sm:py-6 justify-between sm:justify-end fixed bg-white z-10 w-full sm:w-[calc(100%-250px)]">
                    <MobileMenu />

                    <Profile />
                </header>

                <section className="h-[94vh] p-5 mt-14 overflow-auto">{childrenWithProps}</section>
            </div>
        </div>
    )
}

export default TemplateDefault
