import { Link } from "react-router-dom"

interface IQrcode {
    id: string
    name: string
    description: string
    user: any
    patient: any
    qr_code: string
    command?: any
    start_time: string
    days_of_week: string
    order: string | number
    deleted_at: string
    onClose: () => void
}

const Qrcode = ({ id, qr_code }: IQrcode) => {
    return (
        <div className="w-full flex justify-center items-center flex-col">
            <img
                src={`${process.env.REACT_APP_IMAGE_API}/${qr_code}`}
                alt="qrcode"
                style={{ width: '250px', height: '250px' }}
            />

            <Link to={`/routine/${id}`} className="text-gray-700 no-underline">
                <i className="zmdi zmdi-print me-2" />
                Imprimir
            </Link>
        </div>
    )
}

export default Qrcode
