/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import RoutineTable from '../../tables/RoutinesTable'
import IPaginatedPage from '../../interfaces/IPaginatedPage'
import RoutineService from '../../services/routine.service'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import { _pushToastMessage } from '../../helpers/messages'
import RoutineHeader from './RoutineHeader'
import RoutineForm from '../../forms/Routines'
import PaginationComponent from '../../components/PaginationComponent'
import RoutineConfigForm from '../../forms/Routines/RoutineConfigForm'
import RoutineTypeModal from '../../modals/Routine/RoutineTypeModal'
import CreatePreLoadedRoutineForm from '../../forms/Routines/CreatePreLoadedRoutineForm'
import { isAdmin, isCaregiver, isPatient, isTutor } from '../../helpers/reduxHelpers'
import UserService from '../../services/users.service'

const Routines = () => {
    const [data, setData] = useState<IPaginatedPage>({
        current_page: 0,
        data: [],
        first_page_url: '',
        from: 0,
        last_page: 0,
        last_page_url: '',
        links: [],
        next_page_url: '',
        path: '',
        per_page: 0,
        prev_page_url: '',
        to: 0,
        total: 0,
        sortColumn: '',
        sortType: '',
    })

    const [modals, setModals] = useState<any>({
        routineFormModal: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
        routineConfigFormModal: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
        routineTypeModal: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
        routineFormPreLoadedModal: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
    })

    const [patient, setPatient] = useState<any>({ id: 0 })

    const [patients, setPatients] = useState<any>([])

    const { session } = useSelector((state: any) => state)

    const dispatch = useDispatch()

    useEffect(() => {
        _getData()
    }, [])

    const _getData = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            if (isAdmin()) {
                const response = await new RoutineService().getRoutines()
                setData(response.data)
            }

            if (isTutor()) {
                const responsePatients: any = await new UserService().getProfile({})
                setPatients([...[{ id: 0, name: 'Todos' }], ...responsePatients?.data?.data?.list_of_patients_to_tutor])

                if (patient?.id) {
                    const response = await new RoutineService().getRoutinesByPatient({ id: patient.id })
                    setData(response.data)
                }

                if (!patient?.id) {
                    const response = await new RoutineService().getRoutines()
                    setData(response.data)
                }
            }

            if (isCaregiver()) {
                const responsePatients: any = await new UserService().getProfile({})
                setPatients([
                    ...[{ id: 0, name: 'Todos' }],
                    ...responsePatients?.data?.data?.list_of_patients_to_caregiver,
                ])
                if (patient?.id) {
                    const response = await new RoutineService().getRoutinesByPatient({ id: patient.id })
                    setData(response.data)
                }

                if (!patient?.id) {
                    const response = await new RoutineService().getRoutines()
                    setData(response.data)
                }
            }

            if (isPatient()) {
                const response = await new RoutineService().getRoutinesByPatient({ id: session?.profile?.id })
                setData(response.data)
            }

            dispatch(setLoading({ isLoading: false }))
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'No se pudo cargar las rutinas' })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _handleToggleModal = (modal: string, action: string = 'create', data: any = null) => {
        setModals({
            ...modals,
            [modal]: {
                ...modals[modal],
                data: data ?? modals[modal].data,
                action: action,
                open: !modals[modal].open,
            },
        })
    }

    const _handleRoutineTypeModal = (option: number) => {
        switch (option) {
            case 1:
                _handleToggleModal('routineFormModal')
                break
            case 2:
                _handleToggleModal('routineFormPreLoadedModal')
                break
            default:
                break
        }
    }

    return (
        <div className="w-full">
            <CreatePreLoadedRoutineForm
                open={modals.routineFormPreLoadedModal.open}
                handleClose={() => _handleToggleModal('routineFormPreLoadedModal')}
                action={modals.routineFormPreLoadedModal.action}
                getData={_getData}
                data={modals.routineFormPreLoadedModal.data}
            />

            <RoutineForm
                open={modals.routineFormModal.open}
                handleClose={() => _handleToggleModal('routineFormModal')}
                action={modals.routineFormModal.action}
                getData={_getData}
                data={modals.routineFormModal.data}
            />

            <RoutineConfigForm
                open={modals.routineConfigFormModal.open}
                title="Configuración de voz"
                data={modals.routineConfigFormModal.data}
                handleClose={() => _handleToggleModal('routineConfigFormModal')}
                setData={setData}
                patient={patient}
            />

            <RoutineTypeModal
                open={modals.routineTypeModal?.open}
                handleClose={() => _handleToggleModal('routineTypeModal')}
                handleSelectedOption={_handleRoutineTypeModal}
            />

            <div className="w-full">
                <RoutineHeader
                    handleToggleModal={_handleToggleModal}
                    patients={patients}
                    setData={setData}
                    setPatient={setPatient}
                    patient={patient}
                />
            </div>

            <div className="w-full">
                <RoutineTable
                    data={data}
                    setData={setData}
                    onEditRoutine={(data: any) => _handleToggleModal('routineFormModal', 'update', data)}
                    onEditRoutineConfig={(data: any) => _handleToggleModal('routineConfigFormModal', 'update', data)}
                />
            </div>

            <div className="w-full my-5">
                <PaginationComponent pagination={data} onRequestEnd={setData} />
            </div>
        </div>
    )
}

export default Routines
