import { IRoutes } from "../interfaces/IRoutes";
import ConfirmationCode from "../pages/ConfirmationCode";
import Users from "../pages/Users";
import Logs from "../pages/Logs";
import PreLoadedTasks from "../pages/PreLoadedTask";
// import RegisterUser from "../pages/RegisterUser";
import RegisterUserWithToken from "../pages/RegisterUserWithToken";
import RoutineDetails from "../pages/RoutineDetails";
import Routines from "../pages/Routines";
import RoutinesPreLoaded from "../pages/RoutinesPreLoaded";
import SignIn from "../pages/SignIn";
import SignOut from "../pages/SignOut";
import Tasks from "../pages/Tasks";
import TasksToken from "../pages/TasksToken";
import TemplateDefault from "../template/TemplateDefault";
import { Roles } from "./Roles";
import SetTemplate from "./SetTemplate";
import Home from "../pages/Home";
import Recovery from "../pages/Recovery";
import UpdatePassword from "../pages/UpdatePassword";
import TermsAndConditions from "../pages/TermsAndConditions";
import PrivacyPolicies from "../pages/PrivacyPolicies";

const TemplatedUsers = () => SetTemplate(TemplateDefault, Users);
const TemplatedRoutines = () => SetTemplate(TemplateDefault, Routines);
const TemplatedRoutinesPreLoaded = () => SetTemplate(TemplateDefault, RoutinesPreLoaded);
const TemplatedTasks = () => SetTemplate(TemplateDefault, Tasks);
const TemplatedPreLoadedTasks = () => SetTemplate(TemplateDefault, PreLoadedTasks);
const TemplatedLogs = () => SetTemplate(TemplateDefault, Logs);

export const routes: IRoutes[] = [
  {
    component: Home,
    path: "/",
    title: "Home",
    exact: true,
    permissions: [Roles.ALL],
    menu: false,
  },
  {
    component: TemplatedUsers,
    path: "/users",
    title: "Usuarios",
    exact: true,
    permissions: [Roles.ADMIN, Roles.TUTOR, Roles.CUIDADOR],
    menu: {
      name: "Usuarios",
      icon: "zmdi zmdi-account me-2",
      url: "/users",
    },
  },
  {
    component: TemplatedRoutines,
    path: "/routines",
    title: "Rutinas",
    exact: true,
    permissions: [Roles.ALL],
    menu: {
      name: "Rutinas",
      icon: "zmdi zmdi-collection-plus me-2",
      url: "/routines",
    },
    regularExpression: /\/routine\/[0-9]+\/tasks/,
  },
  {
    component: TemplatedRoutinesPreLoaded,
    path: "/routines-pre-loaded",
    title: "Rutinas Pre Cargadas",
    exact: true,
    permissions: [Roles.ADMIN],
    menu: {
      name: "Rutinas Pre Cargadas",
      icon: "zmdi zmdi-upload me-2",
      url: "/routines-pre-loaded",
    },
    regularExpression: /\/pre-loaded-routine\/[0-9]+\/tasks/,
  },
  {
    component: RoutineDetails,
    path: "/routine/:id",
    title: "Rutinas",
    exact: true,
    permissions: [Roles.ALL],
    menu: false,
  },
  {
    component: TemplatedTasks,
    path: "/routine/:id/tasks",
    title: "Tareas",
    exact: true,
    permissions: [Roles.ALL],
    menu: false,
  },
  {
    component: TemplatedPreLoadedTasks,
    path: "/pre-loaded-routine/:id/tasks",
    title: "Tareas",
    exact: true,
    permissions: [Roles.ADMIN],
    menu: false,
  },
  {
    component: TemplatedLogs,
    path: "/logs",
    title: "Logs",
    exact: true,
    permissions: [Roles.ADMIN],
    menu: {
      name: "Logs",
      icon: "zmdi zmdi-file-text me-2",
      url: "/logs",
    },
  },
];

export const AuxiliarRoutes: IRoutes[] = [
  {
    component: ConfirmationCode,
    path: "/confirmation-code",
    title: "Confirmation code",
    exact: true,
    permissions: [Roles.ALL],
    menu: false,
  },
  {
    component: SignIn,
    path: "/signin",
    title: "Sign In",
    exact: true,
    permissions: [Roles.ALL],
    menu: false,
  },
  {
    component: SignOut,
    path: "/signout",
    title: "Sign out",
    exact: true,
    permissions: [Roles.ALL],
    menu: false,
  },
  {
    component: RegisterUserWithToken,
    path: "/register/user/:token",
    title: "Registro de usuario",
    exact: true,
    permissions: [Roles.ALL],
    menu: false,
  },
  // {
  //   component: RegisterUser,
  //   path: "/register",
  //   title: "Registro de usuario",
  //   exact: true,
  //   permissions: [Roles.ALL],
  //   menu: false,
  // },
  {
    component: TasksToken,
    path: "/routines/render/:id",
    title: "Tareas",
    exact: true,
    permissions: [Roles.ALL],
    menu: false,
  },
  {
    component: Recovery,
    path: "/recovery",
    title: "Tareas",
    exact: true,
    permissions: [Roles.ALL],
    menu: false,
  },
  {
    component: UpdatePassword,
    path: "/recovery/password/:token",
    title: "Recuperar contraseña",
    exact: true,
    permissions: [Roles.ALL],
    menu: false,
  },
  {
    component: TermsAndConditions,
    path: "/terms-and-conditions",
    title: "Términos de uso",
    exact: true,
    permissions: [Roles.ALL],
    menu: false,
  },
  {
    component: PrivacyPolicies,
    path: "/privacy-policies",
    title: "Políticas de privacidad",
    exact: true,
    permissions: [Roles.ALL],
    menu: false,
  },
];

export default routes;
