/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker, TagPicker } from "rsuite";
import InputText from "../../components/InputText";
import ModalComponent from "../../components/ModalComponent";
import { useEffect, useState } from "react";
import { validateYupSchema } from "../../helpers/utils";
import ErrorValidation from "../../components/ErrorValidation";
import { _pushToastMessage } from "../../helpers/messages";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/slicers/loader";
import { DatePickerSpanishLocale } from "../../locales/datepicker.locale";
import moment from "moment";
import { CreatePreLoadedRoutineSchema } from "../../validations/routine.validations";
import { days_of_week } from "../../helpers/days_of_week";
import PreLoadedRoutineService from "../../services/pre_loaded_routine.service";

interface IRoutinePreLoadedForm {
    open: boolean
    action: string
    data?: any
    handleClose: () => void
    getData: () => void
}
const RoutinePreLoadedForm = ({ handleClose, action, open, data, getData }: IRoutinePreLoadedForm) => {

    const dispatch = useDispatch();
    const { loader } = useSelector((state: any) => state);
    const [state, setState] = useState({
        data: {
            name: "",
            start_time: moment().hour(0).minute(0).second(0).toDate(),
            days_of_week: [],
            description: ""
        },
        validations: {},
    })

    useEffect(() => {
        if (!open) return
        if (action === "update") {
            const days_of_week = data.days_of_week?.split(",").map((day: string) => parseInt(day));
            days_of_week.sort((a: number, b: number) => a - b);
            const hours = data.start_time?.split(":")
            setState({
                ...state,
                validations: {},
                data: {
                    ...data,
                    days_of_week,
                    start_time: moment().hour(hours[0]).minute(hours[1]).second(0).toDate()
                }
            })
            return;
        }

        setState({
            ...state,
            validations: {},
            data: {
                name: "",
                start_time: moment().hour(0).minute(0).second(0).toDate(),
                days_of_week: [],
                description: ""
            }
        })

    }, [open])

    const _handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setState({
            ...state,
            data: {
                ...state.data,
                [name]: value
            }
        })
    }

    const _handleOnChangeRsuite = ({ name, value }: { name: string, value: string }) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [name]: value
            }
        })
    }

    const _handleSubmit = async () => {
        try {
            const schema = CreatePreLoadedRoutineSchema;
            const validations = await validateYupSchema(schema, state.data);
            setState({
                ...state,
                validations
            })
            if (validations) return

            dispatch(setLoading({ isLoading: true }))

            if (action === "update") {
                await new PreLoadedRoutineService().updateRoutine(data.id, state.data);
            }

            if (action === "create") {
                await new PreLoadedRoutineService().createRoutine(state.data);
            }

            dispatch(setLoading({ isLoading: false }))

            handleClose();

            getData();

            const message = action === "create" ? "Rutina creada con exito" : "Rutina actualizada con exito"
            _pushToastMessage({ type: "success", header: "Exito", text: message })
        } catch (err: any) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: "error", header: "Error", text: err.message })
        }
    }

    return (
        <ModalComponent open={open} title={`${action === "create" ? "Crear rutina" : "Actualizar rutina"}`} handleClose={handleClose} >
            <div className="w-full flex flex-wrap justify-between">

                <div className="mb-2 w-full">
                    <InputText name="name" type="text" label="Nombre" validations={state.validations} onChange={_handleOnChange} value={state.data?.name} />
                </div>

                <div className="mb-2 w-full">
                    <span className={`text-[12px] `}>Dias de la semana</span>
                    <TagPicker
                        data={days_of_week}
                        className="w-full "
                        onChange={(value: any) => _handleOnChangeRsuite({
                            value, name: "days_of_week"
                        })}
                        value={state.data?.days_of_week}
                        placeholder="Informa la hora de inicio de la rutina"
                        loading={loader.isLoading}
                        locale={
                            {
                                emptyMessage: "No hay resultados",
                                searchPlaceholder: "Buscar",
                                noResultsText: "No hay resultados"
                            }
                        }
                    />
                    <ErrorValidation validations={state.validations
                    } name="days_of_week" className="text-red-400 text-[11px] text-end" />
                </div>

                <div className="mb-2 w-full">
                    <span className={`text-[12px] `}>Inicio de la rutina</span>
                    <DatePicker
                        className="w-full "
                        onChange={(value: any) => _handleOnChangeRsuite({
                            value, name: "start_time"
                        })}
                        value={state.data?.start_time}
                        placeholder="Informa la hora de inicio de la rutina"
                        loading={loader.isLoading}
                        locale={DatePickerSpanishLocale}
                        format="HH:mm"
                    />
                    <ErrorValidation validations={state.validations
                    } name="start_time" className="text-red-400 text-[11px] text-end" />
                </div>

                <div className="mb-2 w-full">
                    <span className={`text-[12px] `}>Descripcíon</span>
                    <textarea className="w-full border rounded p-2" name="description" onChange={_handleOnChange} value={state.data?.description}></textarea>
                    <ErrorValidation validations={state.validations
                    } name="description" className="text-red-400 text-[11px] text-end" />
                </div>

                <div className="flex justify-end w-full mt-10">
                    <button className=" button me-3 border-red-400" onClick={handleClose} disabled={loader.isLoading}>Cerrar</button>
                    <button className="button" onClick={_handleSubmit} disabled={loader.isLoading}>Confirmar</button>
                </div>
            </div>
        </ModalComponent>
    )
}

export default RoutinePreLoadedForm;