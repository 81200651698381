/* eslint-disable react-hooks/exhaustive-deps */
import { SelectPicker } from 'rsuite'
import InputText from '../../components/InputText'
import ModalComponent from '../../components/ModalComponent'
import { useEffect, useState } from 'react'
import { validateYupSchema } from '../../helpers/utils'
import { CreateUserSchema, UpdateUserSchema } from '../../validations/user.validations'
import ErrorValidation from '../../components/ErrorValidation'
import { _pushToastMessage } from '../../helpers/messages'
import RolesService from '../../services/roles.service'
import IRole from '../../interfaces/IRole'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import UserService from '../../services/users.service'
import { isAdmin, isCaregiver, isTutor } from '../../helpers/reduxHelpers'

interface IUserForm {
    open: boolean
    action: string
    data?: any
    handleClose: () => void
    getUsers: () => void
}
const UserForm = ({ handleClose, action, open, data, getUsers }: IUserForm) => {
    const dispatch = useDispatch()
    const { loader } = useSelector((state: any) => state)
    const [state, setState] = useState({
        data: {
            name: '',
            email: '',
            role: '',
            phone_number: '',
            password: '',
            confirm_password: '',
        },
        validations: {},
    })

    const [roles, setRoles] = useState<IRole[]>([])

    useEffect(() => {
        if (!open) return

        if (action === 'update') {
            setState({
                ...state,
                validations: {},
                data: {
                    ...data,
                    role: data.roles[0].value,
                    password: '',
                    confirm_password: '',
                },
            })
            return
        }

        setState({
            ...state,
            validations: {},
            data: {
                name: '',
                email: '',
                phone_number: '',
                role: '',
                password: '',
                confirm_password: '',
            },
        })
    }, [open])

    useEffect(() => {
        if (!open) return

        if (roles.length === 0) _getRoles()
    }, [open])

    const _getRoles = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const roles = await new RolesService().getRoles()

            if (isAdmin()) {
                setRoles(roles.data)
            }

            if (isTutor()) {
                const filteredRoles = roles.data.filter(
                    (role: IRole) => role.value === 'paciente' || role.value === 'cuidador'
                )
                setRoles(filteredRoles)
            }

            if (isCaregiver()) {
                const filteredRoles = roles.data.filter((role: IRole) => role.value === 'paciente')
                setRoles(filteredRoles)
            }

            dispatch(setLoading({ isLoading: false }))
        } catch (err: any) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: 'error', header: 'Error', text: 'No fue posible cargar las roles' })
        }
    }

    const _handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setState({
            ...state,
            data: {
                ...state.data,
                [name]: value,
            },
        })
    }

    const _handleOnChangeRsuite = ({ name, value }: { name: string; value: string }) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [name]: value,
            },
        })
    }

    const _handleSubmit = async () => {
        try {
            const schema = action === 'create' ? CreateUserSchema : UpdateUserSchema
            const validations = await validateYupSchema(schema, state.data)
            setState({
                ...state,
                validations,
            })
            if (validations) return

            dispatch(setLoading({ isLoading: true }))

            if (action === 'update') {
                await new UserService().updateUser(data.id, state.data)
            }

            if (action === 'create') {
                await new UserService().createAdminUser(state.data)
            }

            dispatch(setLoading({ isLoading: false }))

            handleClose()
            getUsers()

            const message = action === 'create' ? 'Usuario creado con exito' : 'Usuario actualizado con exito'
            _pushToastMessage({ type: 'success', header: 'Exito', text: message })
        } catch (err: any) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: 'error', header: 'Error', text: err.message })
        }
    }

    return (
        <ModalComponent
            open={open}
            title={`${action === 'create' ? 'Crear usuario' : 'Actualizar usuario'}`}
            handleClose={handleClose}
        >
            <div className="w-full flex flex-wrap justify-between">
                <div className="mb-2 w-full">
                    <InputText
                        name="name"
                        type="text"
                        label="Nombre"
                        validations={state.validations}
                        onChange={_handleOnChange}
                        value={state.data?.name}
                    />
                </div>
                <div className="mb-2 w-full sm:w-[48%]">
                    <InputText
                        name="email"
                        type="text"
                        label="Email"
                        validations={state.validations}
                        onChange={_handleOnChange}
                        value={state.data?.email}
                        disabled={action === 'update'}
                    />
                </div>
                <div className="mb-2 w-full sm:w-[48%]">
                    <InputText
                        name="phone_number"
                        type="text"
                        label="Teléfono"
                        validations={state.validations}
                        onChange={_handleOnChange}
                        value={state.data?.phone_number}
                    />
                </div>

                {action === 'create' && (
                    <>
                        <div className="mb-3 w-full sm:w-[48%]">
                            <InputText
                                name="password"
                                type="password"
                                label="Contraseña"
                                validations={state.validations}
                                onChange={_handleOnChange}
                                value={state.data?.password}
                            />
                        </div>
                        <div className="mb-3 w-full sm:w-[48%]">
                            <InputText
                                name="confirm_password"
                                type="password"
                                label="Confirmar contraseña"
                                validations={state.validations}
                                onChange={_handleOnChange}
                                value={state.data?.confirm_password}
                            />
                        </div>
                    </>
                )}

                <div className="mb-2 w-full">
                    <span className={`text-[12px] `}>Rol</span>
                    <SelectPicker
                        className="w-full "
                        value={state.data?.role}
                        data={roles}
                        onChange={(value: any) =>
                            _handleOnChangeRsuite({
                                value,
                                name: 'role',
                            })
                        }
                        placeholder="Seleccione un rol"
                        loading={loader.isLoading}
                        locale={{
                            emptyMessage: 'No hay resultados',
                            searchPlaceholder: 'Buscar',
                            noResultsText: 'No hay resultados',
                        }}
                    />
                    <ErrorValidation
                        validations={state.validations}
                        name="role"
                        className="text-red-400 text-[11px] text-end"
                    />
                </div>

                <div className="flex justify-end w-full mt-10">
                    <button className=" button me-3 border-red-400" onClick={handleClose} disabled={loader.isLoading}>
                        Cerrar
                    </button>
                    <button className="button" onClick={_handleSubmit} disabled={loader.isLoading}>
                        Confirmar
                    </button>
                </div>
            </div>
        </ModalComponent>
    )
}

export default UserForm
