import { Table, Whisper } from 'rsuite'
import WhisperComponent from '../components/WhisperComponent'
import { useDispatch, useSelector } from 'react-redux'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import RoutineService from '../services/routine.service'
import { orderData } from '../helpers/utils'
import DraggableRow from '../components/DraggableRow'
import { setLoading } from '../redux/slicers/loader'
import { _pushToastMessage } from '../helpers/messages'
import { TouchBackend } from 'react-dnd-touch-backend'
import ModalComponent from '../components/ModalComponent'
import RoutineDetails from '../modals/Routine/RoutineDetails'
import { useState } from 'react'
import ConfirmationModal from '../components/ConfirmationModal'
import { _actionMenuSpeaker } from '../pages/Routines/tableSpeaker'
import { WhisperMenu } from '../components/WhisperMenu'
import Qrcode from '../modals/Routine/Qrcode'
import { Link } from 'react-router-dom'

interface IRoutineTable {
    data: any
    setData: (data: any) => void
    onEditRoutine: (data: any) => void
    onEditRoutineConfig: (data: any) => void
}
const RoutineTable = ({ data, setData, onEditRoutine, onEditRoutineConfig }: IRoutineTable) => {
    const { loader } = useSelector((state: any) => state)

    const [modals, setModals] = useState<any>({
        routineDetailsModal: {
            open: false,
            data: {
                name: '',
                description: '',
                user: {},
                patient: {},
                qr_code: '',
                command: {},
                start_time: '',
                days_of_week: '',
                order: '',
                deleted_at: '',
            },
        },
        qrcodeModal: {
            open: false,
            title: 'Qrcode',
            data: {},
        },
        confirmationModal: {
            open: false,
            title: 'Confirmación',
            data: {},
        },
    })

    const dispatch = useDispatch()

    const isTouchDevice = () => {
        if ('ontouchstart' in window) {
            return true
        }
        return false
    }

    // Assigning backend based on touch support on the device
    const backendForDND: any = isTouchDevice() ? TouchBackend : HTML5Backend

    const handleDragRow = async (source_id: any, target_id: any) => {
        try {
            if (source_id !== target_id) {
                dispatch(setLoading({ isLoading: true }))
                await new RoutineService().reorderRoutines({ source_id, target_id })
                const reordedData = orderData(data.data, source_id, target_id)
                data.data = reordedData
                setData({ ...data })
                dispatch(setLoading({ isLoading: false }))
            }
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'No se pudo reordenar la rutina' })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _handleOnClickRoutine = (e: any) => {
        e.preventDefault()
        const routine = data?.data?.find((item: any) => item.id === parseInt(e.currentTarget.id))
        setModals({ ...modals, routineDetailsModal: { open: true, data: routine } })
    }

    const _handleToggleModal = (modal: string, action: string = 'create', data: any = null) => {
        setModals({
            ...modals,
            [modal]: {
                ...modals[modal],
                data: data ?? modals[modal].data,
                action: action,
                open: !modals[modal].open,
            },
        })
    }

    const _handleConfirmationConfirmationModal = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const { id } = modals.confirmationModal?.data
            await new RoutineService().toggleRoutine(id)
            _handleToggleModal('confirmationModal')
            _pushToastMessage({ type: 'success', header: 'Exito', text: 'Rutina eliminada con exito' })
            dispatch(setLoading({ isLoading: false }))
            const response = await new RoutineService().getRoutines()
            setData(response.data)
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'No se pudo eliminar la rutina' })
            dispatch(setLoading({ isLoading: false }))
            _handleToggleModal('confirmationModal')
        }
    }

    const _handleSelectMenu = (payload: any) => {
        switch (payload.option) {
            case 1:
                setModals({ ...modals, confirmationModal: { open: true, data: payload.payload?.data } })
                break
            case 2:
                onEditRoutine(payload.payload?.data)
                break
            case 3:
                window.location.href = `/routine/${payload.payload?.data.id}/tasks`
                break
            case 4:
                onEditRoutineConfig({ ...payload.payload?.data?.routineconfig })
                break
            case 5:
                window.location.href = `/routine/${payload.payload?.data.id}`
                break
            default:
                break
        }
    }

    const Actions = (payload: any) => {
        const { data } = payload
        const _speaker: any = _actionMenuSpeaker(data, _handleSelectMenu)
        return (
            <Whisper
                placement="auto"
                trigger="click"
                speaker={(whisper_payload: any, ref: any) => WhisperMenu(whisper_payload, ref, _speaker)}
            >
                <button className="w-full">
                    <i className="zmdi zmdi-more cursor-pointer"></i>
                </button>
            </Whisper>
        )
    }

    return (
        <div className="w-full">
            <ModalComponent
                open={modals.routineDetailsModal?.open}
                handleClose={() => _handleToggleModal('routineDetailsModal')}
                title="Rutina"
            >
                <RoutineDetails
                    {...modals.routineDetailsModal?.data}
                    onClose={() => _handleToggleModal('routineDetailsModal')}
                />
            </ModalComponent>

            <ModalComponent
                open={modals.qrcodeModal?.open}
                handleClose={() => _handleToggleModal('qrcodeModal')}
                title="Qrcode"
            >
                <Qrcode {...modals.qrcodeModal?.data} onClose={() => _handleToggleModal('qrcodeModal')} />
            </ModalComponent>

            <ConfirmationModal
                open={modals.confirmationModal.open}
                title={modals.confirmationModal?.title}
                onClose={() => _handleToggleModal('confirmationModal')}
                onConfirm={_handleConfirmationConfirmationModal}
            >
                <p>
                    ¿Está seguro que desea {modals.confirmationModal?.data?.deleted_at ? 'activar' : 'desactivar'} esta
                    rutina?
                </p>
            </ConfirmationModal>

            <DndProvider backend={backendForDND}>
                <Table
                    autoHeight
                    rowHeight={70}
                    className="w-[100%]"
                    data={data.data}
                    locale={{ emptyMessage: 'No hay rutinas' }}
                    loading={loader.isLoading}
                    rowKey="id"
                    renderRow={(children, rowData) => {
                        return rowData ? (
                            <DraggableRow key={rowData.id} rowData={rowData} id={rowData.id} onDrag={handleDragRow}>
                                {children}
                            </DraggableRow>
                        ) : (
                            children
                        )
                    }}
                >
                    <Table.Column flexGrow={1} minWidth={50}>
                        <Table.HeaderCell align="center">ID</Table.HeaderCell>
                        <Table.Cell>
                            {(item) => {
                                return (
                                    <WhisperComponent text={`${item.id}`}>
                                        <span className="size-08">{`${item.id}`}</span>
                                    </WhisperComponent>
                                )
                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1} minWidth={120}>
                        <Table.HeaderCell align="center">Nombre Rutina</Table.HeaderCell>
                        <Table.Cell verticalAlign="middle">
                            {(item) => {
                                return (
                                    <WhisperComponent text={`${item.name}`}>
                                        <a
                                            href="/"
                                            className="text-blue-400 hover:text-blue-400 no-underline active:no-underline"
                                            onClick={_handleOnClickRoutine}
                                            id={`${item.id}`}
                                        >
                                            <span className="size-08">{`${item.name}`}</span>
                                        </a>
                                    </WhisperComponent>
                                )
                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1} minWidth={120}>
                        <Table.HeaderCell align="center">Usuarios</Table.HeaderCell>
                        <Table.Cell verticalAlign="middle">
                            {(item) => {
                                return (
                                    <WhisperComponent capitalizeOff text={`${item.patient?.name}`}>
                                        <span className="size-08">{`${item.patient?.name}`}</span>
                                    </WhisperComponent>
                                )
                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1} minWidth={120}>
                        <Table.HeaderCell align="center">Creada por</Table.HeaderCell>
                        <Table.Cell verticalAlign="middle">
                            {(item) => {
                                return (
                                    <WhisperComponent capitalizeOff text={`${item.user?.name}`}>
                                        <span className="size-08">{`${item.user?.name}`}</span>
                                    </WhisperComponent>
                                )
                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1} minWidth={120}>
                        <Table.HeaderCell align="center">QR</Table.HeaderCell>
                        <Table.Cell verticalAlign="middle">
                            {(item) => {
                                return (
                                    <WhisperComponent capitalizeOff text={`${item.qr_code}`}>
                                        <div className="w-full flex justify-center">
                                            <img
                                                onClick={() => _handleToggleModal('qrcodeModal', 'create', item)}
                                                src={`${process.env.REACT_APP_IMAGE_API}/${item.qr_code}`}
                                                alt="qrcode"
                                                style={{ width: '30px', height: '30px' }}
                                                className="cursor-pointer"
                                            />
                                        </div>
                                    </WhisperComponent>
                                )
                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1} minWidth={120}>
                        <Table.HeaderCell align="center">Tareas</Table.HeaderCell>
                        <Table.Cell verticalAlign="middle">
                            {(item) => {
                                return (
                                    <WhisperComponent
                                        capitalizeOff
                                        text={`${item.tasks?.length} ${
                                            item.tasks?.length === 1 ? ' tarea activa' : 'tareas activas'
                                        }`}
                                    >
                                        <Link to={`/routine/${item.id}/tasks`}>
                                            <span className="size-08">
                                                Ver tareas
                                            </span>
                                        </Link>
                                    </WhisperComponent>
                                )
                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1} minWidth={120}>
                        <Table.HeaderCell align="center">Empeza a las</Table.HeaderCell>
                        <Table.Cell verticalAlign="middle">
                            {(item) => {
                                return (
                                    <WhisperComponent capitalizeOff text={`${item.start_time}`}>
                                        <span className="size-08">{`${item.start_time}`}</span>
                                    </WhisperComponent>
                                )
                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1} minWidth={120}>
                        <Table.HeaderCell align="center">Status</Table.HeaderCell>
                        <Table.Cell verticalAlign="middle">
                            {(item) => {
                                return (
                                    <WhisperComponent capitalizeOff text={`${item.deleted_at ? 'Inactiva' : 'Activa'}`}>
                                        <span className="size-08">{`${item.deleted_at ? 'Inactiva' : 'Activa'}`}</span>
                                    </WhisperComponent>
                                )
                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1} minWidth={120}>
                        <Table.HeaderCell align="center">Acciones</Table.HeaderCell>
                        <Table.Cell verticalAlign="middle" align="center">
                            {(item) => {
                                return <Actions data={item} />
                            }}
                        </Table.Cell>
                    </Table.Column>
                </Table>
            </DndProvider>
        </div>
    )
}

export default RoutineTable
