import instance from './api'
import store from '../redux/store'

class RoutineService {
    _setTokens() {
        const { session } = store.getState()
        const { tokens } = session
        instance.defaults.headers.common['Authorization'] = `Bearer ${tokens.accessToken}`
    }

    async getRoutines() {
        this._setTokens()
        try {
            const response = await instance.get('/api/routines')
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getRoutinesByPatient({ id }: { id: string | number }) {
        this._setTokens()
        try {
            const response = await instance.get('/api/routines/patient/' + id)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async reorderRoutines({ source_id, target_id }: { source_id: string; target_id: string }) {
        this._setTokens()
        try {
            const response = await instance.put('/api/routines/reorder', {
                source_id,
                target_id,
            })
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async createRoutine(routine: any) {
        this._setTokens()
        try {
            const response = await instance.post('/api/routines/create', routine)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async createPreLoadedRoutine(routines: any) {
        this._setTokens()
        try {
            const response = await instance.post('/api/routines/create/pre-loaded', routines)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async updateRoutine(id: number, routine: any) {
        this._setTokens()
        try {
            const response = await instance.put(`/api/routines/update/${id}`, routine)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async deleteRoutine(id: number) {
        this._setTokens()
        try {
            const response = await instance.delete(`/api/routines/delete/${id}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async toggleRoutine(id: number) {
        this._setTokens()
        try {
            const response = await instance.delete(`/api/routines/toggle-status/${id}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async updateRoutineConfigs(id: number, configs: any) {
        this._setTokens()
        try {
            const response = await instance.put(`/api/routines/update/configs/${id}`, configs)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async searchRoutines({ search }: { search: string }) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/routines/search/${search}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }
}

export default RoutineService
